<template>
  <div>
    <div class="d-flex my-5">
      <h3 class="mx-auto">
        <div class="d-flex flex-row fontGrad">
          How does OMNIMODA work?
        </div>
      </h3>
    </div>

    <div class="position-relative" style="height:150px">
      <div class="custom_col ">
        <span class="color-grey-700 position-absolute"
          >STEP 1<i class="fa-regular fa-user mx-2"></i
          ><strong>You</strong> create an invoice in Xero.</span
        >
        <span class="color-grey-700 position-absolute"
          >STEP 2<i class="fa-regular fa-bell  mx-2"></i>Xero notifies
          <strong>Omnimoda</strong> of the new invoice OR we check for unpaid
          invoices</span
        >
        <span class="color-grey-700 position-absolute"
          >STEP 3<i class="fa-regular fa-paper-plane  mx-2"></i> Omnimoda sends
          an <strong>SMS</strong> to the customer with payment details and a
          link to the pay the invoice</span
        >
      </div>
    </div>

    <div class="hide">
      <div class="d-flex hide">
        <div class="step1 mx-3 animation-container">
          <b-img
            src="https://omnimodaStorage.b-cdn.net/Xero%20-%20Logo%20-%20Blue%20-%20cropped.png"
            style="width:80px;"
          ></b-img>
        </div>
        <div class="step2 mx-3 animation-container">
          <i class="fa-solid fa-5x fa-arrow-right-long color-blue-grey-800"></i>
        </div>
        <div class="step3 mx-3 animation-container">
          <i class="fa-solid fa-5x fa-file-invoice-dollar color-purple-700"></i>
        </div>
        <div class="step4 mx-3 animation-container">
          <i class="fa-solid fa-5x fa-arrow-right-long color-blue-grey-800"></i>
        </div>
        <div class="step5 mx-3 animation-container">
          <b-img
            src="https://omnimodaStorage.b-cdn.net/favicon.ico"
            style="width:80px;"
          ></b-img>
          <div class="color-blue-700">OMNIMODA</div>
        </div>
        <div class="step6 mx-3 animation-container">
          <i class="fa-solid fa-5x fa-arrow-right-long color-blue-grey-800"></i>
        </div>
        <div class="step7 mx-3 animation-container">
          <i
            class="fa-solid fa-5x fa-mobile-screen color-indigo-a400 fa-shake"
            style="--fa-animation-delay:9s"
          ></i>
        </div>
        <div class="step8 mx-3 animation-container">
          <i class="fa-solid fa-5x fa-arrow-right-long color-blue-grey-800"></i>
        </div>
        <div>
          <i
            class="step9 fa-solid fa-5x color-green-700 fa-hand-holding-dollar"
          ></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.custom_col {
  display: flex;
  flex-direction: row;
}

.fontGrad {
  background: -webkit-linear-gradient(45deg, #09009f, #00ff95 80%);
  background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@media only screen and (max-width: 1000px) {
  .hide {
    display: none;
  }
  .allrow {
    width: 100%;
  }
  .span1 {
    margin-right: -20px;
  }

  .custom_col {
    flex-direction: column;
  }
}

span {
  opacity: 0;
  font-size: 24px;
}

span:nth-of-type(1) {
  animation: fadeIn ease 12s;
  animation-iteration-count: infinite;
}

span:nth-of-type(2) {
  animation: fadeIn ease 12s;
  animation-iteration-count: infinite;
  animation-delay: 4s;
}

span:nth-of-type(3) {
  animation: fadeIn ease 12s;
  animation-iteration-count: infinite;
  animation-delay: 8s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  30% {
    opacity: 1;
  }
  33% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

.animation-container {
  animation: pulse 12s infinite;
}

.step2 {
  animation-delay: 1.5s;
}

.step3 {
  animation-delay: 3s;
}

.step4 {
  animation-delay: 4.5s;
}

.step5 {
  animation-delay: 6s;
}

.step6 {
  animation-delay: 7.5s;
}

.step7 {
  animation-delay: 9s;
}

.step8 {
  animation-delay: 10.5s;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  2% {
    transform: scale(0.98);
  }
  8% {
    transform: scale(1.3);
  }
  15% {
    transform: scale(0.98);
  }
  20% {
    transform: scale(1);
  }

  100% {
    transform: scale(1);
  }
}
</style>
