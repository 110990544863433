<template>
  <div
    class="mt-auto "
    style="background: rgb(11,30,106);
background: linear-gradient(247deg, rgba(11,30,106,1) 0%, rgba(79,79,79,1) 72%);
 color:white; padding-top: 16px;"
  >
    <div>
      <b-container style="padding:0; border 0;" fluid class="mr-auto">
        <router-link to="pricing" class="footerItem mx-3 my-2 p-3"
          >Pricing</router-link
        >
        <router-link to="about" class="footerItem mx-3 my-2 p-3"
          >About</router-link
        >
        <router-link to="/footer/terms" class="footerItem mx-3 my-2 p-3"
          >Contact Us</router-link
        >
        <i class="fa-brands fa-2x fa-linkedin  mx-3 my-2 p-3s"></i>
        <i class="fa-brands fa-2x fa-facebook  mx-3 my-2 p-3"></i>
      </b-container>
      <b-container style="padding:0; border 0;" fluid class="mr-auto">
        <router-link to="/footer/terms" class=" mx-3 my-2 p-3 footerText"
          >Service Terms</router-link
        >
        <router-link to="/footer/cookie" class=" mx-3 my-2 p-3 footerText"
          >Cookie Policy</router-link
        >
        <router-link to="/footer/use" class=" mx-3 my-2 p-3 footerText"
          >Acceptable Use</router-link
        >
        <router-link to="/footer/privacy" class=" mx-3 my-2 p-3 footerText"
          >Privacy Policy</router-link
        >
      </b-container>
      <b-container style="padding:0; border 0;" fluid class="my-2 p-3">
        Copyright © 2022 Omnimoda - All rights reserved
      </b-container>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "Footer",
  components: {}
};
</script>

<style>
.footerItem {
  vertical-align: super;
}

.footerText {
  white-space: normal;
  display: inline-block;
}
</style>
