var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._m(0),_vm._m(1),_c('div',{staticClass:"hide"},[_c('div',{staticClass:"d-flex hide"},[_c('div',{staticClass:"step1 mx-3 animation-container"},[_c('b-img',{staticStyle:{"width":"80px"},attrs:{"src":"https://omnimodaStorage.b-cdn.net/Xero%20-%20Logo%20-%20Blue%20-%20cropped.png"}})],1),_vm._m(2),_vm._m(3),_vm._m(4),_c('div',{staticClass:"step5 mx-3 animation-container"},[_c('b-img',{staticStyle:{"width":"80px"},attrs:{"src":"https://omnimodaStorage.b-cdn.net/favicon.ico"}}),_c('div',{staticClass:"color-blue-700"},[_vm._v("OMNIMODA")])],1),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex my-5"},[_c('h3',{staticClass:"mx-auto"},[_c('div',{staticClass:"d-flex flex-row fontGrad"},[_vm._v(" How does OMNIMODA work? ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"position-relative",staticStyle:{"height":"150px"}},[_c('div',{staticClass:"custom_col"},[_c('span',{staticClass:"color-grey-700 position-absolute"},[_vm._v("STEP 1"),_c('i',{staticClass:"fa-regular fa-user mx-2"}),_c('strong',[_vm._v("You")]),_vm._v(" create an invoice in Xero.")]),_c('span',{staticClass:"color-grey-700 position-absolute"},[_vm._v("STEP 2"),_c('i',{staticClass:"fa-regular fa-bell mx-2"}),_vm._v("Xero notifies "),_c('strong',[_vm._v("Omnimoda")]),_vm._v(" of the new invoice OR we check for unpaid invoices")]),_c('span',{staticClass:"color-grey-700 position-absolute"},[_vm._v("STEP 3"),_c('i',{staticClass:"fa-regular fa-paper-plane mx-2"}),_vm._v(" Omnimoda sends an "),_c('strong',[_vm._v("SMS")]),_vm._v(" to the customer with payment details and a link to the pay the invoice")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"step2 mx-3 animation-container"},[_c('i',{staticClass:"fa-solid fa-5x fa-arrow-right-long color-blue-grey-800"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"step3 mx-3 animation-container"},[_c('i',{staticClass:"fa-solid fa-5x fa-file-invoice-dollar color-purple-700"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"step4 mx-3 animation-container"},[_c('i',{staticClass:"fa-solid fa-5x fa-arrow-right-long color-blue-grey-800"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"step6 mx-3 animation-container"},[_c('i',{staticClass:"fa-solid fa-5x fa-arrow-right-long color-blue-grey-800"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"step7 mx-3 animation-container"},[_c('i',{staticClass:"fa-solid fa-5x fa-mobile-screen color-indigo-a400 fa-shake",staticStyle:{"--fa-animation-delay":"9s"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"step8 mx-3 animation-container"},[_c('i',{staticClass:"fa-solid fa-5x fa-arrow-right-long color-blue-grey-800"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('i',{staticClass:"step9 fa-solid fa-5x color-green-700 fa-hand-holding-dollar"})])
}]

export { render, staticRenderFns }