import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import * as Sentry from "@sentry/vue";

//shared data store across components
import store from "./store";

//socket client
import io from "socket.io-client";
import VueSocketIOExt from "vue-socket.io-extended";
//const socket = io.connect("http://localhost:3000");
const socket = io.connect("https://api.omnimoda.co");
Vue.use(VueSocketIOExt, socket, { autoConnect: false, store });

//styling framework
import {
  BootstrapVue
  /*LayoutPlugin,
  FormPlugin,
  TablePlugin,
  TooltipPlugin,
  ButtonPlugin,
  NavPlugin,
  NavbarPlugin,
  LinkPlugin,
  AlertPlugin,
  FormSelectPlugin,
  SpinnerPlugin,
  FormInputPlugin,
  FormTextareaPlugin*/
} from "bootstrap-vue";
Vue.use(
  BootstrapVue
); /*
Vue.use(LayoutPlugin);
Vue.use(FormPlugin);
Vue.use(TablePlugin);
Vue.use(TooltipPlugin);
Vue.use(ButtonPlugin);
Vue.use(NavPlugin);
Vue.use(NavbarPlugin);
Vue.use(LinkPlugin);
Vue.use(AlertPlugin);
Vue.use(FormSelectPlugin);
Vue.use(SpinnerPlugin);
Vue.use(FormInputPlugin);
Vue.use(FormTextareaPlugin); */
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

// AJAX framework
import axios from "axios";
import VueAxios from "vue-axios";
Vue.use(VueAxios, axios);

//annoying include to deal with scolling.

import vuescroll from "vue-scroll";
Vue.use(vuescroll);

// top level interceptor - dealing with API 401 (unauthorised)

axios.interceptors.response.use(
  function(response) {
    return response;
  },
  function(error) {
    if (error.response.status === 401) {
      console.log("interceptor auth failed");

      store.dispatch("logout");
      router.push("/login");
    }
    return Promise.reject(error);
  }
);

//custom MODAL

import VModal from "vue-js-modal";

//scrollbar

Vue.use(VModal, { componentName: "custModal" });

Vue.config.productionTip = false;

Sentry.init({
  Vue,
  tunnel: "/tunnel/",
  dsn:
    "https://b0f7e27597374308235a5bb666f15014@o4506725654265856.ingest.us.sentry.io/4506733761658880",
  integrations: [
    Sentry.browserTracingIntegration({ router }),
    Sentry.replayIntegration()
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for tracing.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
  tracePropagationTargets: ["localhost", "https://www.omnimoda.co"],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0
});

//Import Mixpanel SDK
import mixpanel from "mixpanel-browser";

// Near entry of your product, init Mixpanel
mixpanel.init("29ce87bc08a78e6b9bb8450d1a96538d", {
  debug: true,
  track_pageview: true,
  persistence: "localStorage"
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
// eslint-disable-next-line
