<template>
  <div style="max-width:600px" class="mx-auto my-4">
    <h1>FAQs</h1>
    <div v-for="(faq, index) in faqs" :key="index">
      <div @click="toggleOpen(index)" class="p-3" style="cursor:pointer">
        <b-row
          ><div>
            <strong>{{ faq.title }}</strong>
          </div>

          <i v-show="!show['_' + index]" class="fa-solid fa-plus ml-auto"></i
          ><i
            v-show="show['_' + index] == true"
            class="fa-solid fa-minus ml-auto"
          ></i
        ></b-row>
      </div>

      <div v-show="show['_' + index] == true">
        <div class="text-left" style="width:90%" v-html="faq.body"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Auth",
  components: {},
  props: {},
  mounted() {},
  data() {
    return {
      show: {},
      faqs: [
        {
          title: "Is this product for me?",
          body:
            "Omnimoda is designed for businesses who issue invoices from Xero and want to automate SMS reminders. Businesses that don't issue invoices or issue very few invoices each month may not experience the full benefits of Omnimoda."
        },
        {
          title: "How does billing work?",
          body:
            "We offer month and annual subscriptions. Each month, on the subscription's anniversary, credits will be added to your account. All unused credits will roll-over at the end of the month."
        },
        {
          title: "Can I top-up my credits?",
          body:
            "You can purchase one-off credit top-ups. Like subscription credits, these do not expire."
        },
        {
          title: "Can I cancel my subscription?",
          body:
            "You can cancel you subscription at any time. You Omnimoda account will stay open, allowing you access to your history on the platform."
        },
        {
          title: "What data do you collect and hold?",
          body:
            "We store a list of your Xero contacts. We also use analytics (privacy based) and a session recording tool to look for ways to improve the product."
        },
        {
          title: "This is great but I wish it did ... ?",
          body:
            "We love this sort of feedback. This is built this for you. What do you want in to do? Use the Contact us page to email your idea"
        }
      ]
    };
  },

  methods: {
    toggleOpen(indx) {
      let i = "_" + indx;

      if (!this.show[i]) {
        this.$set(this.show, i, true);
        return;
      }

      this.show[i] = !this.show[i];

      return;
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
