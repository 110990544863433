<template>
  <div class="my-5">
    <div class="m-3 p-3" style="max-width: 1200px">
      <div v-show="showHeader">
        <h1 class="my-3 color-grey-800">Simple, transparent pricing</h1>
        <div class="mb-4 color-indigo-900">No contract, No suprise fees</div>
      </div>
    </div>

    <div>
      <ToggleButton
        v-model="isMonthly"
        :labels="{ checked: 'Monthly', unchecked: 'Annual' }"
        :width="140"
        :height="40"
        :font-size="18"
      />
      <div style="height: 20px">
        <div v-show="!isMonthly" class="mx-auto color-green-a400">
          Get 2 months free
        </div>
      </div>
      <div class="d-md-flex justify-content-center">
        <template v-for="price in prices">
          <div :key="price.name">
            <div class="pricing-card bg-blue-grey-50 text-left p-2 m-4">
              <div class="font-weight-bold mt-1 ms-2 color-blue-grey-800">
                {{ price.name }}
              </div>
              <div class="font-weight-light mt-1 ms-2" style="font-size:small">
                {{ price.button1 }}
              </div>
              <div
                class="ms-2 mt-4 mb-2 color-blue-grey-900"
                style="font-size:xxx-large; font-weight:600;"
              >
                ${{ isMonthly ? price.price.Monthly : price.price.Annual }}
                <span
                  class="ms-2 mt-4 mb-2 color-blue-grey-700"
                  style="font-size: large;  vertical-align: super;"
                >
                  {{ isMonthly ? "Month" : "Year" }}
                </span>
              </div>

              <div>
                <div class="bg-blue-grey-900 color-grey-50 getstarted-button">
                  <router-link class="color-grey-50" to="/signup"
                    >Get started</router-link
                  >
                </div>
              </div>
            </div>
            <div>
              <ul
                class="pricing-info color-blue-900 mx-auto px-0"
                style="font-size:smaller;"
              >
                <li class="my-2">
                  Realtime SMS conversations<i
                    class="fa-solid fa-circle-check mt-1"
                    style="color:rgb(11, 30, 106); text-align:left;"
                  ></i>
                </li>
                <li class="my-2">
                  Mobile number validation<i
                    class="fa-solid fa-circle-check mt-1 "
                    style="color:rgb(11, 30, 106);text-align:left;"
                  ></i>
                </li>
                <li class="my-2">
                  Automate you SMS sending<i
                    class="fa-solid fa-circle-check mt-1"
                    style="color:rgb(11, 30, 106);text-align:left;"
                  ></i>
                </li>
                <li class="my-2">
                  <span>
                    Full integration with Xero
                    <i
                      class="fa-regular fa-circle-question"
                      v-b-tooltip.hover
                      title="Omnimoda integrates with contact and invoice data"
                    ></i></span
                  ><i
                    class="fa-solid fa-circle-check mt-1"
                    style="color:rgb(11, 30, 106);text-align:left;"
                  ></i>
                </li>
                <li class="mb-5">
                  <span style="font-weight:bolder;"
                    >{{
                      price.credits == 0
                        ? "1000 starter credits"
                        : price.credits + " credits added every month"
                    }}
                    <i
                      class="fa-regular fa-circle-question"
                      v-b-tooltip.hover
                      :title="
                        price.credits == 0
                          ? 'Pay as you go accounts manage their credits with Top Ups.'
                          : 'Approximately ' +
                            price.credits / 20 +
                            ' messages. Credits are issued on the monthly anniversary of the subscription and never expire.'
                      "
                    ></i
                  ></span>
                </li>
              </ul>
            </div>
          </div>
        </template>
      </div>
    </div>
    <div v-if="false">
      <b-button @click="this.getStripeSession()">get session</b-button>
      <div></div>
    </div>
  </div>
</template>

<script>
import { ToggleButton } from "vue-js-toggle-button";

export default {
  components: {
    ToggleButton
  },
  name: "account",
  props: {
    showHeader: { type: Boolean, default: true },
    hideFooter: { type: Boolean, default: false }
  },
  data() {
    return {
      focused: 0,
      nameState: true,
      emailState: true,
      editName: "",
      editEmail: "",
      resp: "",
      sessionID: "",
      accountData: "",
      test: 0,
      credits: 0,
      prices: [
        {
          price: { Monthly: 25, Annual: 250 },
          pricecode: { month: "price_1Ml0JFIU6fXqzdC5YWbld7IC" },
          credits: 0,
          name: "Pay as you go",
          button1: "For low volume users. Buy credit top-ups as you need them.",
          activeSub: false
        },
        {
          price: { Monthly: 35, Annual: 350 },
          pricecode: { month: "price_1Ml0K7IU6fXqzdC5Pduo9HuO" },
          credits: 1600,
          button1: "For users who issue up to 60 invoices/month",
          name: "Everyday",
          activeSub: false
        },
        {
          price: { Monthly: 50, Annual: 500 },
          pricecode: { month: "price_1Ml0K7IU6fXqzdC5Pduo9HuO" },
          credits: 3500,
          button1: "For users who issue 60+ invoices/month",
          name: "Everyday Plus",
          activeSub: false
        }
      ],
      priceCards: [
        {
          price: { monthly: 18, annual: 180 },
          pricecode: { monthly: "price_1Ml0JFIU6fXqzdC5YWbld7IC" },
          color: "#fff",
          font: "#2563EB",
          credits: 0,
          name: "dailysubtest1",
          active: false
        },
        {
          price: { monthly: 25, annual: 250 },
          pricecode: { monthly: "price_1Ml0K7IU6fXqzdC5Pduo9HuO" },
          color: "#fff",
          font: "#2563EB",
          credits: 0,
          name: "dailysubtest1",
          active: false
        } /*
        {
          price: { monthly: 25, annual: 250 },
          pricecode: { monthly: 25, annual: 250 },
          color: "#fff",
          font: "#2563EB",
          credits: 0,
          name: "P.A.Y.G",
          active: false
        },
        {
          price: { monthly: 35, annual: 350 },
          color: "#26b1f9",
          font: "#1D4ED8",
          credits: 2000,
          name: "Everyday Plan",
          active: true
        },
        {
          price: { monthly: 50, annual: 500 },
          color: "#1e8fd6",
          font: "#1E40AF",
          credits: 4500,
          name: "Everyday Plus Plan",
          active: false
        } */
      ],
      isMonthly: true
    };
  },
  methods: {
    getStripeSession(price) {
      alert(JSON.stringify(price));

      let url = process.env.VUE_APP_BASE_API_URL + "stripe";

      this.axios
        .post(
          url,
          {
            price: price.pricecode.monthly,
            mode: "subscription"
          },
          { withCredentials: true }
        )
        .then(response => {
          this.resp = response.data;
          this.sessionID = response.data.id;
          this.changePlan();
        });
    },
    getPrice(d) {
      if (this.isMonthly) {
        return d.monthly;
      } else {
        return d.annual;
      }
    },
    changePlan() {
      // You will be redirected to Stripe's secure checkout page
      this.$refs.checkoutRef.redirectToCheckout();
    },
    priceToggle() {
      this.isMonthly = !this.isMonthly;
    }
  },
  mounted() {},
  computed: {
    paymentPeriod() {
      if (this.isMonthly) {
        return "month";
      }

      return "year";
    },
    tttitle() {
      if (this.credits == 0) {
        return "Pay as you go accounts manage their credits with Top Ups.";
      }
      return (
        "Approximately " +
        this.credits / 20 +
        " messages. Credits are issued on the monthly anniversary of the subscription and never expire."
      );
    }
  }
};
</script>

<style scoped>
.pricing-card {
  border-radius: 15px;
  width: 260px;
  max-width: 260px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  position: relative;
}

.pricing-info {
  width: 260px;
  max-width: 260px;
}

@media only screen and (max-width: 700px) {
  .pricing-card {
    width: 80%;
    max-width: 400px;
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .pricing-info {
    width: 80%;
    max-width: 400px;
  }
}

.getstarted-button {
  border-radius: 8px;
  text-align: center;
  padding: 8px;
  cursor: pointer;
}

ul {
  list-style-type: none;
  width: 100%;
}

li {
  width: 100%;
  justify-content: space-between;
  display: flex;
  text-align: left;
}
</style>
