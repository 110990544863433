<template>
  <div class="my-5">
    <h1 class="my-4 color-grey-800">
      Get in touch <i class="fa-regular fa-envelope"></i>
    </h1>
    <div style="max-width:600px" class="mx-auto my-4">
      <div>
        <b-form @submit.prevent="email">
          <b-row cols="1" cols-md="2">
            <b-col class="text-left">
              Your name
              <b-form-input
                id="input-1"
                v-model="form.name"
                type="text"
                required
                placeholder="Your name"
                class="mb-4"
                style="background:aliceblue"
              ></b-form-input
            ></b-col>
            <b-col class="text-left">
              Your email
              <b-form-input
                id="input-1"
                v-model="form.email"
                type="email"
                required
                placeholder="Your email"
                class="mb-4"
                style="background:aliceblue"
              ></b-form-input
            ></b-col>
          </b-row>
          <b-row class="mx-1">
            Message
            <b-form-textarea
              id="textarea"
              v-model="form.text"
              placeholder="Enter something..."
              required
              style="background:aliceblue"
            ></b-form-textarea>
          </b-row>
          <br />
          <b-button type="submit" variant="outline-primary" class="px-5"
            >Send
            <i
              class="fa-solid fa-arrow-up fa-rotate-by"
              style="--fa-rotate-angle: 45deg;"
            ></i
          ></b-button>
          <hr />
        </b-form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Auth",
  components: {},
  props: {},
  mounted() {},
  data() {
    return {
      text: "",
      signUpText: "",
      validemail: "",
      form: {},
      showEmailInUseMessage: false
    };
  },

  methods: {
    email() {
      this.axios
        .post(
          process.env.VUE_APP_BASE_API_URL + "contactus",
          {
            ...this.form
          },
          { withCredentials: true }
        )
        .then(() => {
          this.successToast();
          this.form = {};
        });
    },
    successToast() {
      this.$root.$bvToast.toast(`Email sent`, {
        title: "Success",
        variant: "success",
        toaster: "b-toaster-bottom-right",
        solid: true
      });
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
