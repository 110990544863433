import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import { EventBus } from "../eventBus";

/* not sure I need this?
const vuexLocal = new VuexPersistence({
  storage: window.localStorage
});
*/

Vue.use(Vuex);

const defaultState = () => {
  return {
    auth: {
      auth: "",
      room: "",
      user: ""
    },
    connected: false,
    error: "",
    messages: { all: [] },
    message: [],
    contacts: [],
    inbox: [
      {
        sourceContactID: "all",
        contactBusinessName: "all",
        lastMessage: "2999-01-01T00:00:00.000Z"
      }
    ],
    messagesRecipientGrouped: []
  };
};

export default new Vuex.Store({
  state: {
    auth: {
      auth: "",
      room: "",
      user: ""
    },
    connected: false,
    error: "",
    messages: { all: [] },
    message: [],
    contacts: [],
    inbox: [{ sourceContactID: "all", contactBusinessName: "all" }],
    messagesRecipientGrouped: []
  },
  mutations: {
    SOCKET_CONNECT(state) {
      state.connected = true;
      console.log("socket connected");

      if (state.auth.auth) {
        // rejoin room

        Vue.prototype.$socket.client.emit("JOIN", state.auth.room);
      }
    },
    SOCKET_DISCONNECT(state) {
      state.connected = false;
    },
    SOCKET_MESSAGE(state, message) {
      state.message.push(message);
      console.log("message received");
    },
    SOCKET_MESSAGE_INBOUND(state, message) {
      state.messages.push(message);
      console.log("message received");
    },
    SOCKET_MESSAGE_FROM_QUEUE(state, message) {
      state.message.push(message);
      console.log("message received");
    },
    SOCKET_NEW_CHAT_MESSAGE(state, message) {
      console.log("message received");

      // adding to indivudal contact if exists, adding contact + message if doesn't

      if (message.recipientGUID in state.messages) {
        state.messages[message.recipientGUID].push(message);
      } else {
        //preserving reactivity
        Vue.set(state.messages, message.recipientGUID, []);
        state.messages[message.recipientGUID].push(message);
      }

      //adding to all array

      state.messages.all.push(message);

      //updating inbox if exists.

      if (state.inbox) {
        const index = state.inbox.findIndex(
          e => e.sourceContactID == message.recipientGUID
        );

        let newObj = state.inbox[index];

        newObj.lastMessage = message.dateUTC;

        Vue.set(state.inbox, index, newObj);
      }

      //emitting a scroll to bottom event

      EventBus.$emit("scrollToBottom", ".");
    },
    SOCKET_HELLO_WORLD(state, message) {
      state.message = message;
    },
    SOCKET_ERROR(state, message) {
      state.error = message.error;
    },
    SOCKET_UPDATE_CREDITS(state, message) {
      state.auth.credits = message.credits;
    },
    setAuth(state, d) {
      console.log(JSON.stringify(d));

      state.auth = d;
    },
    clearAuth(state, emptyState) {
      state.auth = emptyState.auth;
      state.contacts = [];
      state.messages = emptyState.messages;
      state.inbox = emptyState.inbox;
    },
    storeAllMessages(state, d) {
      state.messages.all.unshift(...d.r.reverse());
    },
    storeInbox(state, d) {
      state.inbox.unshift(...d.r.reverse());
    },
    storeSingleMessage() {
      //store in all
      //state.messages.all.unshift
      //store in recipient array
    },
    storeMessagesRecipientGrouped(state, d) {
      // need to sort this out properly.

      //if key exists push into Array if not add

      if (d.p.contact in state.messages) {
        state.messages[d.p.contact].unshift(...d.p.data.reverse());
      } else {
        //preserving reactivity

        Vue.set(state.messages, d.p.contact, d.p.data.reverse());
      }

      /*update unread messages.

      let read = d.p.data.reduce((n, { unreadFlag }) => n + unreadFlag, 0);

      let contact = state.inbox.find(o => o.recipientGUID == d.p.contact);

      contact.unreadMessages = contact.unreadMessages - read;

      */
    },
    storeContacts(state, d) {
      state.contacts = d.r;
    },
    toggleContactStyling(state, d) {
      let p = state.contacts.findIndex(e => e.contactID === d.cid);

      if (state.contacts[p]._rowVariant) {
        delete state.contacts[p]._rowVariant;
      } else {
        state.contacts[p]._rowVariant = "success";
      }
    }
  },
  getters: {
    getChat: state => state.message,
    getCredits: state => state.auth.credits,
    getAuthStatus: state => state.auth.loggedIn,
    getUserGUID: state => state.auth.user,
    getAllMessages: state => {
      return state.messages.all;
    },
    getMessages: state => {
      return state.messages;
    },
    getInbox: state => {
      return [...state.inbox]
        .sort((a, b) => {
          return a.lastMessage > b.lastMessage;
        })
        .reverse();
    },
    getMessagesByRecipient: state => c => {
      // temp. data will be [{abcde:[{message: '1234'}, {message:'5678'}]}]
      return state.messages[c];
    },
    getContacts: state => state.contacts,
    getContact: state => c => {
      return state.contacts.filter(contact => contact.contactID == c);
    }
  },
  actions: {
    logout({ commit }) {
      console.log("message received action");
      commit("clearAuth", defaultState());

      //need to reset state.
    }
  },
  plugins: [createPersistedState()]
});
