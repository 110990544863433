<template>
  <div>
    <b-navbar
      toggleable="md"
      style="background: rgb(11,30,106);
background: linear-gradient(247deg, rgba(11,30,106,1) 0%, rgba(79,79,79,1) 72%);
font-family: 'Roboto Mono', monospace; list-style:none; font-size: 20px; color: rgb(218, 218, 218); padding-left:32px;"
    >
      <b-navbar-brand
        class="text-center"
        align-v="center"
        href="/"
        style="font-family: 'Passion One'; font-size: 38px; color: rgb(218, 218, 218); letter-spacing:.05px;"
        >Omnimoda</b-navbar-brand
      >
      <b-navbar-toggle target="nav-collapse">
        <template #default="{ expanded }">
          <i
            class="fa-solid fa-circle-chevron-up fa-xl"
            style="color: #007bff;"
            v-if="expanded"
          ></i>
          <i
            class="fa-solid fa-circle-chevron-down fa-xl"
            style="color: #007bff;"
            v-if="!expanded"
          ></i> </template
      ></b-navbar-toggle>
      <b-collapse id="nav-collapse" is-nav>
        <b-nav-item
          v-if="!this.$store.state.auth.auth"
          style="text-align:start"
        >
          <router-link to="/">
            <b-button variant="outline-light"
              ><i class="fa-solid fa-house mr-2"></i>Home</b-button
            ></router-link
          >
        </b-nav-item>

        <b-nav-item v-if="this.$store.state.auth.auth" style="text-align:start">
          <router-link to="/panel/contacts"
            ><b-button variant="outline-light"
              ><i class="fa-regular fa-comments mr-2"></i>Workbench</b-button
            ></router-link
          >
        </b-nav-item>
        <b-nav-item
          class="float-md-right"
          v-if="!this.$store.state.auth.auth"
          style="text-align:start"
        >
          <router-link to="/Signup" style="text-align:start"
            ><b-button variant="outline-light"
              ><i class="fa-regular fa-address-card mr-2"></i>Sign up</b-button
            ></router-link
          ></b-nav-item
        >
        <b-nav-item
          class="float-md-right"
          v-if="!this.$store.state.auth.auth"
          style="text-align:start"
        >
          <router-link to="/Login"
            ><b-button variant="outline-light"
              ><i class="fa-solid fa-arrow-right-to-bracket mr-2"></i
              >Login</b-button
            ></router-link
          >
        </b-nav-item>
        <b-nav-item
          class="float-md-right"
          v-if="this.$store.state.auth.auth"
          style="text-align:start"
        >
          <b-button variant="outline-light" @click="logout"
            ><i class="fa-solid fa-arrow-right-from-bracket mr-2"></i>Log
            Out</b-button
          >
        </b-nav-item>
        <b-nav-item
          class="float-md-right"
          v-if="!this.$store.state.auth.auth"
          style="text-align:start"
        >
          <router-link to="/Pricing" style="text-align:start"
            ><b-button variant="outline-light"
              ><i class="fa-solid fa-dollar-sign mr-2"></i>Pricing</b-button
            ></router-link
          ></b-nav-item
        >
        <b-nav-item
          class="float-md-right"
          v-if="this.$store.state.auth.auth"
          style="text-align:start"
        >
          <router-link to="/topup" style="text-decoration-line: none;"
            >Credits: {{ this.credits }}
            <i class="fa fa-plus" aria-hidden="true"></i>
          </router-link>
        </b-nav-item>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
export default {
  name: "Nav",
  methods: {
    logout() {
      this.$store.dispatch("logout");
      this.$router.push("/");
    }
  },
  computed: {
    credits() {
      return this.$store.getters.getCredits;
    }
  }
};
</script>

<style scoped>
.this {
  color: rgb(218, 218, 218);
}
</style>
