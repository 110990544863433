import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

function authOnlyGuard(to, from, next) {
  //var isAuthenticated = false;
  //this is just an example. You will have to find a better or
  // centralised way to handle you localstorage data handling
  //isAuthenticated = localStorage.getItem("vuex");

  if (JSON.parse(localStorage.getItem("vuex")).auth.auth) {
    next(); // allow to enter route
  } else {
    if (process.env.VUE_APP_BASE_API_URL != "https://apidev.omnimoda.co/") {
      console.log("vuex auth failed");
      next("/"); // go to '/login';
    }
    next();
  }
}

const routes = [
  //HOME AND VISITOR ROUTES

  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue")
  },

  {
    path: "/settings",
    name: "Settings",
    beforeEnter: authOnlyGuard,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Settings.vue")
  },

  {
    path: "/pricing",
    name: "Pricing",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Pricing.vue")
  },

  {
    path: "/Signup",
    name: "Signup",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/auth/signup")
  },
  {
    path: "/Login",
    name: "Login",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/auth/login")
  },
  {
    path: "/footer/terms",
    name: "termsofserver",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/footerPages/terms")
  },
  {
    path: "/footer/cookie",
    name: "cookiepolicy",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/footerPages/cookiepolicy")
  },
  {
    path: "/footer/privacy",
    name: "privacypolicy",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/footerPages/privacy.vue")
  },
  {
    path: "/footer/use",
    name: "use",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/footerPages/use.vue")
  },
  {
    path: "/resetPassword",
    name: "resetPassword",

    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/auth/resetPassword")
  },
  {
    path: "/Auth",
    name: "Auth",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ "../views/Auth.vue")
  },
  {
    path: "/Connect",
    name: "Connect",
    beforeEnter: authOnlyGuard,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "member" */ "../views/connect/connect")
  },
  {
    path: "/connect/redirect/xero",
    name: "/connect/redirect/xero",
    beforeEnter: authOnlyGuard,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "member" */ "../views/connect/redirects/xero")
  },
  {
    path: "/stripe/success",
    name: "/stripe/success",
    beforeEnter: authOnlyGuard,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "member" */ "../views/stripeSuccess")
  },
  {
    path: "/topup",
    name: "/topup",
    beforeEnter: authOnlyGuard,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "member" */ "../views/topup")
  },

  {
    path: "/emailValidation",
    name: "/emailValidation",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "emailValidation" */ "../views/auth/emailValidation"
      )
  },

  //PANEL ROUTES

  {
    path: "/panel",
    name: "panel",
    beforeEnter: authOnlyGuard,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "member" */ "../views/Panel.vue"),
    children: [
      {
        path: "account",
        name: "account",
        beforeEnter: authOnlyGuard,
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "member" */ "../../src/views/panel/account"
          )
      },
      {
        path: "automations",
        name: "automations",
        beforeEnter: authOnlyGuard,
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "member" */ "../views/panel/automations")
      },
      {
        path: "automation",
        name: "automation",
        beforeEnter: authOnlyGuard,
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "member" */ "../views/panel/automation")
      },
      {
        path: "automationNew",
        name: "automationNew",
        beforeEnter: authOnlyGuard,
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "member" */ "../views/panel/automationNew"
          )
      },
      {
        path: "connect",
        name: "connect",
        beforeEnter: authOnlyGuard,
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "member" */ "../views/connect/connect")
      },
      {
        path: "connections",
        name: "connections",
        beforeEnter: authOnlyGuard,
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "member" */ "../views/connect/connections"
          )
      },
      {
        path: "messages",
        name: "messages",
        beforeEnter: authOnlyGuard,
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "member" */ "../views/panel/messages"),
        props: true
      },

      //------- CONTACTS

      {
        path: "contact",
        name: "contact",
        beforeEnter: authOnlyGuard,
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "member" */ "../views/panel/contact")
      },

      {
        path: "contacts",
        name: "contacts",
        beforeEnter: authOnlyGuard,
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/panel/contacts")
      },

      {
        path: "contactNew",
        name: "contactNew",
        beforeEnter: authOnlyGuard,
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "member" */ "../views/panel/contactNew")
      },

      //------ GROUPS

      {
        path: "groups",
        name: "groups",
        beforeEnter: authOnlyGuard,
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "member" */ "../views/panel/groups")
      },
      {
        path: "group",
        name: "group",
        beforeEnter: authOnlyGuard,
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "member" */ "../views/panel/group")
      },
      {
        path: "groupNew",
        name: "groupNew",
        beforeEnter: authOnlyGuard,
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "member" */ "../views/panel/groupNew")
      },
      {
        path: "noticeboard",
        name: "noticeboard",
        beforeEnter: authOnlyGuard,
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "member" */ "../views/panel/noticeboard")
      },
      //test and dev routes

      {
        path: "/NotLoggedIn",
        name: "NotLoggedIn",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/NotLoggedIn.vue")
      },
      {
        path: "/LoggedIn",
        name: "LoggedIn",
        beforeEnter: authOnlyGuard,
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/LoggedIn.vue")
      }
    ]
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
