<template>
  <div
    id="app"
    class="d-flex flex-column min-vh-100 vw-mobile "
    style="background-color: white;"
  >
    <Nav style="font-color: black" />

    <div
      class="wrapper flex-grow-1"
      style="display: flex; flex-direction: column;"
    >
      <router-view />
    </div>
    <Footer v-if="pageCheck" />
  </div>
</template>

<script>
import Nav from "./views/Nav";
import Footer from "./views/Footer";

export default {
  name: "app",
  components: {
    Nav,
    Footer
  },
  computed: {
    pageCheck() {
      if (this.$route.path.includes("panel")) {
        return false;
      } else {
        return true;
      }
    }
  }
};
</script>

<style>
#app {
  font-family: "Poppins", "Open Sans", Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #101d29;
}

* {
  font-family: "Poppins", "Open Sans", Avenir, Helvetica, Arial, sans-serif;
}

#nav {
  padding: 30px;
  display: show;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

a.router-link-exact-active {
  text-decoration: none !important;
  color: rgb(0, 176, 255) !important;
}

.grow:hover {
  transform: scale(1.1);
}

.frameFocus {
  background-color: white;
  border: 1px;
  border-style: solid;
  border-color: #e1e1e1;
}

/* Animation */

@keyframes fadeInUp {
  from {
    transform: translate3d(0, 10px, 0);
  }

  50% {
    transform: translate3d(0, 0, 0);
    opacity: 0.8;
  }

  to {
    transform: translate3d(0, 0, 0);
    opacity: 1.1;
  }
}

@-webkit-keyframes fadeInUp {
  from {
    transform: translate3d(0, 20px, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

.animated {
  animation-duration: 2s;
  animation-fill-mode: both;
  -webkit-animation-duration: 2s;
  -webkit-animation-fill-mode: both;
}

.animatedFadeInUp {
  opacity: 0;
}

.fadeInUp {
  opacity: 0;
  animation-name: fadeInUp;
  -webkit-animation-name: fadeInUp;
}

.card {
  border-radius: 6px;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1);
  background-color: #fff;
  margin-bottom: 20px;
  position: relative;
  z-index: 1;
  border: none !important;
  padding: 15px 15px 10px 15px;
}

@media only screen and (max-width: 450px) {
  .vw-mobile {
    max-width: 100vw;
    overflow: hidden;
  }
}
</style>
