<template>
  <div>
    <b-container fluid style="padding:0; border 0;">
      <div style="position:absolute; width:100%;">
        <b-alert show dismissible fade class="py-5" style="opacity:0.85;"
          ><i class="fa-solid fa-hammer fa-xl mx-2"></i>This site is still under
          construction. Coming in 2 weeks!!!<i
            class="fa-solid fa-thumbs-up fa-xl mx-2"
          ></i
        ></b-alert>
      </div>
      <div
        class="py-5"
        style="padding:0; border 0; background-image: url('https://omnimodaStorage.b-cdn.net/lady_sms.webp'); background-size: cover;   background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center; "
      >
        <b-container fluid>
          <b-row cols="1" cols-lg="2">
            <b-col style="padding: 6em;"
              ><b-row class="my-4"
                ><span
                  style="padding-right:50px; letter-spacing: -0.05em; font-size:52px; line-height: 60px; font-family: 'Poppins','Montserrat', sans-serif; font-weight: 700; text-align:left"
                  >Omnimoda is your SMS answer for Xero</span
                >
              </b-row>
              <b-row class="mt-4 mb-5"
                ><span
                  style=" padding-right:50px; color: #404040; letter-spacing: 1px; font-size:22px; line-height:32px; font-family: 'Poppins','Montserrat', sans-serif; font-weight: 500; text-align:left"
                  ><mark style="opacity:.7; "
                    >Speed up payments, improve customer communications, all
                    hands-free with our automation tools
                  </mark></span
                ></b-row
              >
              <b-row class="mt-5 mb-2"
                ><router-link to="/Signup" style="text-align:start">
                  <b-button
                    style="font-size:20px; line-height:36px;"
                    class="px-5 mr-auto"
                    pill
                  >
                    Try Now
                  </b-button></router-link
                ></b-row
              >
            </b-col>
            <b-col
              class=""
              style="padding-left:0px; padding-right:0px; padding-top:20px;"
            >
            </b-col>
          </b-row>
        </b-container>
      </div>
    </b-container>

    <b-container
      fluid
      class="py-5 "
      Style=" background: rgb(225,245,254);
background: linear-gradient(204deg, rgba(225,245,254,1) 36%, rgba(250,250,250,1) 92%); "
    >
      <b-row class="my-5">
        <span class="mx-auto">
          <span
            style="color: #404040; letter-spacing: 1px; font-size:62px; line-height:62px; font-family: 'Poppins','Montserrat', sans-serif; font-weight: 600;"
            >Built for
          </span>
          <b-img
            src="https://omnimodaStorage.b-cdn.net/Xero%20-%20Logo%20-%20Blue%20-%20cropped.png"
            fluid
            class="hoverzoom"
            alt="Responsive image"
            style="width:100px;"
          ></b-img>
        </span>
      </b-row>
    </b-container>

    <b-container fluid style="color: #404040;">
      <b-row cols="1" cols-lg="2" class="my-5">
        <b-col class="p-4" style="height:400px;">
          <div
            class="hoverzoom"
            style="padding-top:20px; background-image: url(https://omnimodaStorage.b-cdn.net/businessmanlookingatphon_706316.webp);background-repeat: no-repeat;
height:100%"
          ></div>
        </b-col>
        <b-col style="text-align:left;" class="p-4"
          ><b-row class="my-5 mx-auto reveals" style="width:80%"
            ><div
              style="padding:6px; box-shadow: blue 0px 0px 0px 2px inset, rgb(255, 255, 255) 10px -10px 0px -3px, rgb(31, 193, 27) 10px -10px, rgb(255, 255, 255) 20px -20px 0px -3px, rgb(255, 217, 19) 20px -20px, rgb(255, 255, 255) 30px -30px 0px -3px, rgb(255, 156, 85) 30px -30px, rgb(255, 255, 255) 40px -40px 0px -3px, rgb(255, 85, 85) 40px -40px;
              margin: auto; letter-spacing: 0.0em; font-size:28px; line-height: 32px; font-family: 'Poppins','Montserrat', sans-serif; font-weight: 600; text-align:left"
            >
              SMS is the most effective way to get your customer's attention.
            </div>
          </b-row>
          <b-row class="my-5 mx-auto" style="width:80%"
            ><div
              style="box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px;;padding:6px;
              margin: auto; color: #404040; letter-spacing: 1px; font-size:18px; line-height:32px; font-family: 'Poppins','Montserrat', sans-serif; font-weight: 400; text-align:left"
            >
              SMS has a 98% open rate, and the highest click through rate of all
              digial media.
            </div></b-row
          ></b-col
        >
      </b-row>
    </b-container>
    <div
      class="my-5"
      Style=" background: rgb(225,245,254);
background: linear-gradient(204deg, rgba(225,245,254,1) 36%, rgba(250,250,250,1) 92%); "
    >
      <b-container class="py-4">
        <b-row cols="1" cols-md="3" class="my-5"
          ><b-col class="p-4 ">
            <i
              style="color:#1e91d5;"
              class="fas fa-file-invoice-dollar fa-5x m-3"
            ></i>
            <div
              class="m-2 color-blue-900"
              style="font-weight:bold; font-size:24px; "
            >
              Get paid sooner!
            </div>
            <div class="m-2">
              SMS your invoices, invoice payment reminders and overdue invoice
              notices and more ...
            </div> </b-col
          ><b-col class="p-4"
            ><i style="color:#1e91d5;" class="fas fa-gears fa-5x m-3"></i>
            <div
              class="m-2 color-blue-900"
              style="font-weight:bold; font-size:24px;"
            >
              Automated
            </div>
            <div class="m-2">
              Sit back and let us do all the work. Our app will automatically
              send an SMS when you create an invoice in Xero, payment is due,
              and payment is overdue. You control the schedule.
            </div></b-col
          ><b-col class="p-4"
            ><i style="color:#1e91d5; " class="fas fa-check fa-5x m-3"></i>
            <div
              class="m-2 color-blue-900"
              style="font-weight:bold; font-size:24px;"
            >
              Simple
            </div>
            <div class="m-2 ">
              Our app takes care of all the integrations. No extra steps, no
              extra apps and app subscriptions, and no coding required. Turnkey
              ready, and ready to go ...
            </div></b-col
          ></b-row
        >
      </b-container>
    </div>

    <b-container>
      <pricing hideFooter="true" />
    </b-container>
    <div
      class="my-5 py-5"
      Style=" background: rgb(225,245,254);
background: linear-gradient(204deg, rgba(225,245,254,1) 36%, rgba(250,250,250,1) 92%); "
    >
      <b-container class="my-5" style="font-size: 36px;">
        Interested?
        <router-link to="/Signup" style="text-align:start"
          ><b-button variant="success" pill
            >Yes, I'm interested
            <i class="fa-regular fa-face-smile"></i></b-button
        ></router-link>
      </b-container>
    </div>
    <b-container> <explainer /></b-container>
    <b-container class="my-5">
      <div class="image_container my-5 mx-auto">
        <img src="https://omnimodaStorage.b-cdn.net/screens.webp" />
      </div>
    </b-container>
    <div
      class="my-5 py-5"
      Style=" background: rgb(225,245,254);
background: linear-gradient(204deg, rgba(225,245,254,1) 36%, rgba(250,250,250,1) 92%); "
    >
      <b-container> <contact /></b-container>
    </div>
    <b-container> <faqs /></b-container>
  </div>
</template>

<script>
// @ is an alias to /src

import pricing from "./Pricing.vue";
import contact from "./contactUs.vue";
import faqs from "./faqs";
import explainer from "./home_explainer_animation.vue";

export default {
  name: "Home",
  data() {
    return {};
  },
  components: { pricing, contact, faqs, explainer },
  methods: {
    reveal() {
      var reveals = document.querySelectorAll(".reveal");
      for (var i = 0; i < reveals.length; i++) {
        var windowHeight = window.innerHeight;
        var elementTop = reveals[i].getBoundingClientRect().top;
        var elementVisible = 150;
        if (elementTop < windowHeight - elementVisible) {
          reveals[i].classList.add("active");
        } else {
          reveals[i].classList.remove("active");
        }
      }
    }
  },
  created() {
    window.addEventListener("scroll", this.reveals);
  },
  destroyed() {
    window.removeEventListener("scroll", this.reveals);
  }
};
</script>

<style scoped>
.bulletpoints {
  font-size: 16px;
  color: #3c3d41;
  padding-right: 2em;
  letter-spacing: 0.5px;
}

mark {
  background: #dcd7d7;
}

.hoverzoom {
  transition: background-size 0.5s ease;
  background-size: 105%;
}

.hoverzoom:hover {
  background-size: 120%;
}

.reveal {
  position: relative;
  transform: translateY(150px);
  opacity: 0;
  transition: 1s all ease;
}

.reveal.active {
  transform: translateY(0);
  opacity: 1;
}

.image_container {
  position: relative;
  top: 0;
  left: 0;
  height: 400px;
}

.image1 {
  position: absolute;
  top: 0;
  left: -30px;
  height: inherit;
}

.image2 {
  position: absolute;
  top: 0;
  left: 190px;
  height: inherit;
}

.image3 {
  position: absolute;
  top: 0;
  left: 390px;
  height: inherit;
}

.image4 {
  position: absolute;
  top: 0;
  left: 600px;
  height: inherit;
}

@media only screen and (max-width: 1000px) {
  .image_container {
    display: none;
  }
}
</style>
